<template>
  <div
    :class="['login-container__content-appbar', { 'in-cabinet': isCabinet }]"
  >
    <!--      this is a logo      -->
    <div class="burger" v-if="isCabinet" @click="$emit('openMenu')">
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 24H40.5"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.5 12H40.5"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.5 36H40.5"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="logo">
      <img src="@/assets/logo.svg" alt="logo" @click="push" />
    </div>
    <div class="info" v-if="contacts">
      <p>{{contacts.phone}}</p>
      <p>{{contacts.address}}</p>
    </div>
    <div class="icons-group">
      <v-icon @click="$emit('sendMessage')">send-message</v-icon>
      <v-icon
        v-if="isCabinet"
        :class="[{ isBell: messagesCount > 0 }]"
        :style="{ '--varMC': messagesCount }"
        @click="$emit('showMessages')"
      >
        bell
      </v-icon>
      <v-icon v-if="isCabinet" @click="$emit('logout')">signout</v-icon>
    </div>
  </div>
</template>
<script>
import VIcon from "@/components/icons/VIcon";
// import SendMessageIcon from "@/components/icons/SendMessageIcon";

export default {
  name: "AppBar",
  components: { VIcon },
  props: {
    isCabinet: {
      type: Boolean,
      default: false,
    },
    messagesCount: {
      type: Number,
      default: 0,
    },
    contacts: Object,
  },
  methods: {
    push() {
      if (this.$store.state.AuthModule.token.length > 0) {
        if (!this.$route.path.match('funds/list')) this.$router.push('/cabinet/funds/list')
      }
      else if (!this.$route.path.match('login')) this.$router.push('/cabinet/funds/list')
    }
  }
};
</script>

<style scoped lang="scss">
.login-container__content-appbar {
  display: flex;
  flex: 0 1 auto;
  padding: 32px 42px;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  @media screen and (min-width: 961px) {
    padding: 32px 44px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    opacity: 0.5;
    right: 0;
    bottom: 0;
    border-bottom: 2px solid $RWM-grey-super-light;
  }

  &.in-cabinet {
    @media screen and (min-width: 961px) {
      padding: 32px 44px 32px 44px;
      margin-left: -44px;
    }
    & .info {
      max-width: 0;
      overflow: hidden;
      transition: all 300ms;
      & p {
        white-space: nowrap;
      }
      @media screen and (min-width: 1200px) {
        max-width: 100%;
      }
    }
  }

  & .burger {
    padding-right: 50px;
    transition: all 0.3s;
    cursor: pointer;
    color: $RWM-dark;
    @media screen and (min-width: 961px) {
      display: none;
    }
  }

  & .logo {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    @media screen and (min-width: 961px) {
      flex: 0 1 140px;
    }

    & img {
      max-width: 100%;
      max-height: 36px;
      object-fit: contain;
      object-position: top;
      @media screen and (min-width: 961px) {
        max-height: 100%;
        cursor: pointer;
      }
    }
  }

  & .info {
    display: none;
    transition: all 0.3s;
    @media screen and (min-width: 961px) {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      align-content: center;
      padding-left: 44px;
    }

    & p {
      text-align: left;

      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      color: $RWM-dark;
    }
  }

  & .icons-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex: 1 1 auto;
    color: $RWM-grey;
    @media screen and (min-width: 1200px) {
    }

    & .v-icon {
      margin: 0 23px;
    }
  }
}
</style>
